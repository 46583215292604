<template>
    <div class="add_declare">
        <div class="add_form">
            <el-form
                ref="form"
                label-position="left"
                size="small"
                label-width="80px"
                style="padding-top: 15px"
            >
                <el-form-item label="客户" required>
                    <el-select
                        filterable
                        v-model="businessExpenseDO.customerId"
                        size="small"
                        :filter-method="conListFilter"
                        style="width: 270px"
                        placeholder="请输入客户名称搜索选择"
                        clearable
                        @change="getCustomer"
                    >
                        <el-option
                            v-for="item in customerList"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="客户名称">
                    <el-input
                        v-model="businessExpenseDO.customerName"
                        size="small"
                        style="width: 270px"
                        placeholder="请输入客户名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="报销金额" required>
                    <el-input
                        size="small"
                        style="width: 270px"
                        placeholder="请输入"
                        v-model="businessExpenseDO.expenseAmount"
                        @input="
                            businessExpenseDO.expenseAmount =
                                businessExpenseDO.expenseAmount.replace(/[^0-9.]/g, '')
                        "
                    ></el-input>
                </el-form-item>
                <el-form-item label="报销类型" required>
                    <el-select
                        v-model="businessExpenseDO.expenseType"
                        size="small"
                        style="width: 270px"
                        placeholder="请选择报销类型"
                        clearable
                    >
                        <el-option
                            v-for="item in $cost.reimbType()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    label="关联申报"
                    required
                    v-if="businessExpenseDO.expenseType && businessExpenseDO.expenseType != 1"
                >
                    <el-button
                        v-if="!declareData.customer"
                        @click="addDeclare()"
                        type="primary"
                        size="mini"
                        style="color: #2370eb; background: #ffffff; border-color: #2370eb"
                        >添加</el-button
                    >
                    <p class="declareData" v-if="declareData.customer">
                        <span
                            >{{ declareData.customer.companyName }}
                            的
                            {{ typeStr }}申报</span
                        >

                        <span class="circle" @click="declareData = {}">
                            <i class="el-icon-circle-close"></i>
                        </span>
                    </p>
                </el-form-item>
            </el-form>
            <p class="detailed">
                报销凭证
                <span @click="addDetalied">添加凭证</span>
            </p>
            <div
                style="position: relative; padding-top: 34px"
                v-for="(item, index) in expenseDetailList"
                :key="index"
            >
                <span class="toast_title">凭证{{ index + 1 }}</span>
                <span
                    class="toast_title delete"
                    v-if="expenseDetailList.length > 1"
                    @click="delDetailed(index)"
                    >删除</span
                >
                <el-form ref="form" size="small" label-position="left" label-width="80px">
                    <el-form-item label="备注信息">
                        <el-input
                            size="small"
                            type="textarea"
                            rows="5"
                            v-model="item.remark"
                            style="width: 270px"
                            placeholder="请输入"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="凭证">
                        <el-upload
                            class="avatar-uploader"
                            list-type="picture-card"
                            :action="action"
                            accept=".jpg, .png, .gif, .pdf,.doc,.docx"
                            :on-progress="progress"
                            :ref="'businessLicense' + index"
                            multiple
                            :on-success="
                                (res, file) => {
                                    return onBusinessLicense(res, index);
                                }
                            "
                            :before-upload="beforeAvatarUploads"
                        >
                            <el-button
                                slot="default"
                                size="small"
                                type="primary"
                                style="background: #fff; padding: 6px 24px; color: #2370eb"
                                >添加</el-button
                            >
                            <div
                                slot="file"
                                slot-scope="{ file }"
                                style="text-align: center"
                                v-if="file.response && file.response.data"
                            >
                                <img
                                    v-if="
                                        file.response.data.indexOf('.jpg') != -1 ||
                                        file.response.data.indexOf('.png') != -1 ||
                                        file.response.data.indexOf('.gif') != -1
                                    "
                                    class="el-upload-list__item-thumbnail"
                                    :src="file.response.data"
                                />
                                <span
                                    v-if="
                                        file.response.data.indexOf('.pdf') != -1 ||
                                        file.response.data.indexOf('.PDF') != -1
                                    "
                                >
                                    <img src="@/img/pdfUrl.png" alt="" />
                                </span>
                                <span v-if="file.response.data.indexOf('.doc') != -1">
                                    <img src="@/img/word.png" alt="" />
                                </span>
                                <span class="el-upload-list__item-actions">
                                    <span
                                        v-if="!disabled"
                                        class="el-upload-list__item-delete"
                                        @click="handleDownload(file)"
                                    >
                                        <i class="el-icon-download"></i>
                                    </span>
                                    <span
                                        v-if="!disabled"
                                        class="el-upload-list__item-delete"
                                        @click="handleRemove(file, index)"
                                    >
                                        <i class="el-icon-delete"></i>
                                    </span>
                                </span>
                            </div>
                        </el-upload>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div slot="footer" class="bottom-but">
            <el-button @click="onClose" round style="padding: 7px 20px" size="mini"
                >取 消</el-button
            >
            <el-button
                type="primary"
                style="margin-right: 15px; background: #2370eb; padding: 7px 20px"
                @click="declareAdd"
                round
                size="mini"
                >提 交</el-button
            >
        </div>
        <el-dialog
            :visible.sync="dialogDeclare"
            width="800px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    关联申报
                </div>
            </template>
            <div>
                <Declare ref="declare" @close="handleClose" @choiceDeclare="choiceDeclare" />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { reimbAdd } from '@/api/reimb/reimb.js';
import { declareGet } from '@/api/cost/declare.js';
import { customerList } from '@/api/report/report.js';
import Declare from './declare.vue';
export default {
    name: '',
    props: {},
    components: {
        Declare,
    },
    data() {
        return {
            businessExpenseDO: {
                customerName: '',
            },
            expenseDetailList: [
                {
                    expenseDetailAttachmentDOList: [],
                },
            ],
            businessDeclareId: '',
            fileList: [],
            userType: sessionStorage.getItem('userType'),
            customerList: [],
            loading: false,
            action: this.$url.url() + '/file/local/upload/contract',
            file: {},
            dialogDeclare: false,
            declareData: {},
            disabled: false,
            typeStr: '',
            searchVal: {
                name: '',
            },
        };
    },
    methods: {
        // 客户列表
        getCustomerList() {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (this.searchVal.name) {
                data.param.name = this.searchVal.name;
            }
            customerList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.customerList = res.data.list;
                }
            });
        },
        conListFilter(val) {
            if (val) {
                this.searchVal.name = val;
                this.getCustomerList();
            } else {
                this.searchVal.name = '';
                this.getCustomerList();
            }
        },
        getCustomer(val) {
            let index = this.customerList.findIndex((item) => {
                return item.id == val;
            });
            console.log(index);
            this.businessExpenseDO.customerName = this.customerList[index].companyName;
        },
        declareGet(id) {
            let data = {
                param: {
                    id: id,
                },
            };
            return declareGet(data);
        },
        progress() {
            this.loading = true;
        },
        handlePreview(file) {
            // console.log(file);
        },
        beforeAvatarUploads(file, index) {
            this.file = file;
            const isLt2M = file.size / 1024 / 1024 < 20;
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 20MB!');
            }
            return isLt2M;
        },
        // 上传附件
        onBusinessLicense(response, index) {
            // console.log(response, index);
            this.loading = false;
            if ((response.code = 200)) {
                this.$message.success('附件上传成功');
                this.expenseDetailList[index].expenseDetailAttachmentDOList.push({
                    attachmentUrl: response.data,
                    attachmentName: this.file.name,
                });
                if (this.fileList.length - 1 > index) {
                    this.fileList.splice(index, 1, { url: response.data });
                    return;
                }
                this.fileList.splice(index, 0, { url: response.data });
            } else {
                this.$message.success('附件上传失败');
            }
        },
        // 添加明细
        addDetalied() {
            this.expenseDetailList.push({
                remark: '',
                expenseDetailAttachmentDOList: [],
            });
        },
        // 删除明细
        delDetailed(index) {
            this.expenseDetailList.splice(index, 1);
        },
        // 新增申报
        async declareAdd() {
            if (!this.businessExpenseDO.customerId) {
                return this.$message.error('请选择客户');
            }
            if (!this.businessExpenseDO.expenseAmount) {
                return this.$message.error('请输入报销金额');
            }
            if (!this.businessExpenseDO.expenseType) {
                return this.$message.error('请选择报销类型');
            }
            if (!this.declareData.id && this.businessExpenseDO.expenseType != 1) {
                return this.$message.error('请选择关联申报');
            }
            let res = await this.declareGet(this.declareData.id);
            if (res.data.status != 4 && this.businessExpenseDO.expenseType != 1) {
                this.declareData = {};
                return this.$message.error('关联申报已更新，请重新选择');
            }
            let data = {
                param: {
                    businessExpenseDO: this.businessExpenseDO,
                    expenseDetailList: this.expenseDetailList,
                },
            };
            data.param.businessExpenseDO.declareId = this.declareData.id;
            reimbAdd(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        addDeclare() {
            if (!this.businessExpenseDO.customerId) {
                return this.$message.error('请选择客户');
            }
            this.dialogDeclare = true;
            setTimeout(() => {
                this.$refs.declare.getData(this.businessExpenseDO.customerId);
            });
        },
        handleClose() {
            this.dialogDeclare = false;
        },
        choiceDeclare(row) {
            this.typeStr = '';
            this.dialogDeclare = false;
            this.declareData = row;
            // console.log(row);
            row.declareDetailList.forEach((item, index) => {
                if (index == row.declareDetailList.length - 1) {
                    this.typeStr += this.$cost.declareDetailType(item.type);
                } else {
                    this.typeStr += this.$cost.declareDetailType(item.type) + '、';
                }
            });
        },

        // 下载
        handleDownload(file) {
            // console.log(file.url);
            if (file.url.indexOf('.doc') != -1 || file.url.indexOf('.docx') != -1) {
                window.open('https://view.officeapps.live.com/op/view.aspx?src=' + file.url);
            } else {
                window.open(file.url, '_blank');
            }
        },
        //删除
        handleRemove(file, index) {
            // console.log(this.$refs["businessLicense" + i]);
            for (
                let i = 0;
                i < this.expenseDetailList[index].expenseDetailAttachmentDOList.length;
                i++
            ) {
                if (
                    this.expenseDetailList[index].expenseDetailAttachmentDOList[i].attachmentUrl ==
                    file.response.data
                ) {
                    this.expenseDetailList[index].expenseDetailAttachmentDOList.splice(i, 1);
                    this.$refs['businessLicense' + index][0].uploadFiles.splice(i, 1);
                }
            }
            this.expenseDetailList[index].expenseDetailAttachmentDOList = JSON.parse(
                JSON.stringify(this.expenseDetailList[index].expenseDetailAttachmentDOList),
            );
        },
        onClose() {
            for (let i in this.expenseDetailList) {
                this.$refs['businessLicense' + i][0].uploadFiles = [];
            }
            this.businessExpenseDO = {
                customerName: '',
            };
            this.expenseDetailList = [
                {
                    expenseDetailAttachmentDOList: [],
                },
            ];
            this.declareData = {};
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.add_declare {
    height: 100%;
    width: 100%;
    .add_form {
        height: calc(100% - 49px);
        overflow-y: auto;
        .el-form {
            width: 85%;
            margin: 0 auto;
            /deep/ .el-form-item__label {
                font-weight: 600 !important;
            }
        }
        .info {
            display: flex;
            span {
                flex: 1;
            }
        }
        .detailed {
            height: 48px;
            line-height: 48px;
            border-top: 1px dashed #eeeeee;
            border-bottom: 1px dashed #eeeeee;
            padding: 0 35px;
            font-size: 14px;
            color: #666666;
            font-weight: 600;
            span {
                float: right;
                cursor: pointer;
                padding: 3px 11px;
                color: #2370eb;
                border: 1px solid #2370eb;
                border-radius: 2px;
                line-height: 1.2;
                margin-top: 13px;
            }
        }
        .all_total {
            height: 48px;
            line-height: 48px;
            border-top: 1px dashed #eeeeee;
            border-bottom: 1px dashed #eeeeee;
            padding: 0 35px;
            font-size: 14px;
            color: #333;
            font-weight: 600;
            em {
                float: right;
                font-style: normal;
            }
        }
        .toast_title {
            display: block;
            width: 56px;
            height: 28px;
            position: absolute;
            top: 6px;
            left: 12px;
            background: rgba(35, 112, 235, 0.07);
            border-radius: 1px;
            border: 1px solid #2370eb;
            font-size: 18px;
            text-align: center;
            transform: scale(0.5);
            color: #2370eb;
        }
        .delete {
            left: 50px;
            background: rgba(208, 2, 27, 0.05);
            border-radius: 1px;
            border: 1px solid #d0021b;
            color: #d0021b;
            cursor: pointer;
        }
        .remark_info {
            font-size: 12px;
            color: #999999;
            padding: 13px 35px;
            span {
                color: #333;
            }
        }
    }
    .bottom-but {
        width: 100%;
        height: 48px;
        background: #fff;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid #e5e5e5;
    }
    .el-upload-list__item-thumbnail {
        width: 80px;
        height: 80px;
    }
}
/deep/ .el-upload-list__item:hover {
    background-color: #fff;
}
.declareData {
    border-radius: 2px;
    border: 1px dashed #2370eb;
    display: inline-block;
    padding: 0 20px;
    color: #2370eb;
    font-size: 12px;
    display: flex;
    align-items: center;
    .circle {
        font-size: 16px;
        margin-left: auto;
        cursor: pointer;
    }
}
/deep/ .el-upload-list__item:hover {
    background-color: #fff;
}
/deep/ .el-upload--picture-card {
    background-color: transparent;
    width: auto;
    height: auto;
    border: none;
    line-height: 1;
    display: block;
    text-align: left;
}
/deep/ .el-upload-list__item.is-success {
    width: 80px !important;
    height: 80px !important;
}
</style>
