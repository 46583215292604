<template>
    <div class="recharge_box">
        <el-form
            label-position="right"
            label-width="120px"
            size="small"
            style="flex: 1; margin-top: 20px"
        >
            <el-form-item label="账户名称" required>
                <el-select
                    v-model="form.launchId"
                    filterable
                    placeholder="请选择"
                    style="width: 270px"
                    @change="getBalanceAmount"
                >
                    <el-option
                        v-for="item in launchDataList"
                        :key="item.id"
                        :label="item.account"
                        :value="item.id"
                    >
                        <span style="float: left">{{ item.account }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">
                            <el-tooltip
                                class="item"
                                effect="dark"
                                content="余额不足1000元"
                                placement="bottom"
                            >
                                <i v-if="1000 - item.balanceAmount > 0" class="el-icon-warning"></i>
                            </el-tooltip>
                        </span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="余额">
                <div>
                    <span
                        v-if="form.launchId"
                        style="
                            background: #f9f9f9;
                            border-radius: 2px;
                            padding: 6px 10px;
                            margin-right: 10px;
                        "
                    >
                        <i
                            :class="
                                oneLaunch.refreshBalanceStatus == 1
                                    ? 'el-icon-success'
                                    : oneLaunch.refreshBalanceStatus == 2
                                    ? 'el-icon-warning'
                                    : ''
                            "
                        ></i>
                        {{ oneLaunch.balanceAmount + '元' || '- -' }}
                    </span>
                    <span
                        v-if="form.launchId"
                        @click="launchAmountUpdate"
                        style="cursor: pointer; color: #2069dd"
                    >
                        <i class="el-icon-refresh-right"> </i>
                        刷新
                    </span>
                    <span v-else> - - </span>
                </div>
            </el-form-item>
            <el-form-item label="充值金额" required>
                <el-input
                    style="width: 270px; margin-right: 5px"
                    placeholder="请输入内容"
                    v-model="form.rechargeAmount"
                ></el-input>
                元
            </el-form-item>
        </el-form>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666; margin-left: 270px"
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >提 交</el-button
            >
        </div>
    </div>
</template>
<script>
import { launchList, launchAmountUpdate } from '@/api/user/launch/manage.js';
import { addRecharge } from '@/api/user/launch/pay.js';
export default {
    data() {
        return {
            form: {},
            launchDataList: [],
            oneLaunch: {},
        };
    },
    methods: {
        launchList() {
            let data = {
                param: {},
                pageSize: 0,
                pageNum: 0,
            };
            launchList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.launchDataList = res.data.list;
                }
            });
        },
        getBalanceAmount(val) {
            this.oneLaunch = this.launchDataList.find((item) => item.id == val);
        }, // 余额刷新
        launchAmountUpdate() {
            let data = {
                param: {
                    channelType: this.oneLaunch.channelType,
                    id: this.oneLaunch.id,
                    userName: this.oneLaunch.userName,
                    password: this.oneLaunch.password,
                    secretKey: this.oneLaunch.secretKey,
                },
            };
            if (this.oneLaunch.channelType == 2) {
                data.param.apiSecret = this.oneLaunch.apiSecret;
            }
            launchAmountUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    console.log(res);
                    this.launchList();
                    this.oneLaunch.balanceAmount = res.data[0].balanceAmount;
                }
            });
        },
        onSubmit() {
            if (!this.form.launchId) {
                return this.$message.error('请选择账户');
            }
            if (!this.form.rechargeAmount) {
                return this.$message.error('请输入充值金额');
            }
            let data = {
                param: {
                    ...this.form,
                },
            };
            addRecharge(data).then((res) => {
                if (res.code == 200) {
                    this.form = {};
                    this.oneLaunch = {};
                    this.$message.success('操作成功');
                    this.onClose();
                }
            });
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.recharge_box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
</style>
