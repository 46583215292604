<template>
    <div class="invoice_box">
        <div
            style="
                margin-top: 20px;
                flex: 1;
                overflow: auto;
                padding=bottom: 50px;
            "
        >
            <el-form
                label-width="75px"
                label-position="left"
                size="small"
                ref="form"
                style="margin-left: 52px"
            >
                <el-form-item label="客户" required>
                    <el-select
                        v-model="companyData"
                        size="small"
                        style="width: 290px"
                        placeholder="请搜索选择"
                        @change="getContractList"
                        :remote-method="remoteMethod"
                        remote
                        filterable
                        clearable
                        value-key="id"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.companyName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关联合同">
                    <el-select
                        filterable
                        v-model="contracId"
                        style="width: 290px; margin-right: 27px"
                        clearable
                        placeholder="请选择"
                        @change="getContracData(contracId)"
                        value-key="id"
                    >
                        <el-option
                            v-for="item in contractData"
                            :key="item.id"
                            :label="item.contractNo"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <div class="contractData" v-if="data.contractNo">
                    <p>
                        <span>合同编号</span>
                        <span>{{
                            data.contractNo ? data.contractNo : '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>客户名称</span>
                        <span>{{
                            data.companyName ? data.companyName : '- -'
                        }}</span>
                    </p>
                    <p>
                        <span>合同金额</span>
                        <span>{{
                            data.contractAmount ? data.contractAmount : '- -'
                        }}</span>
                    </p>
                </div>
                <el-form-item label="签署公司" required>
                    <el-select
                        v-model="form.typeB"
                        style="width: 290px; margin-right: 27px"
                        clearable
                        :disabled="!!data.contractNo"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="item in $tableDataHandle.selectTypeB()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="自定义" required v-if="form.typeB == 9">
                    <el-input
                        v-model="form.contractB"
                        :disabled="!!data.contractNo"
                        placeholder="请输入"
                        style="width: 290px; margin-right: 27px"
                    ></el-input>
                </el-form-item>
                <el-form-item label="开票金额" required>
                    <el-input
                        style="width: 290px"
                        :placeholder="
                            contracId === ''
                                ? '请输入开票金额'
                                : '剩余可开票金额：' + canInvoice
                        "
                        v-model="form.invoiceAmount"
                    ></el-input>
                </el-form-item>

                <el-form-item label="开票类别" required>
                    <el-select
                        v-model="form.invoiceCategory"
                        style="width: 290px"
                        placeholder="请选择"
                    >
                        <el-option label="电子发票" :value="1"> </el-option>
                        <el-option label="纸质发票" :value="2"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票类型" required>
                    <el-select
                        v-model="form.invoiceType"
                        style="width: 290px"
                        placeholder="请选择"
                    >
                        <el-option label="增值税普通发票" :value="1">
                        </el-option>
                        <el-option label="增值税专用发票" :value="2">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="开票名称" required>
                    <el-select
                        allow-create
                        filterable
                        :remote-method="remoteInvoice"
                        remote
                        v-model="invoiceNameVal"
                        size="small"
                        style="width: 290px"
                        placeholder="请选择搜索或直接输入"
                        value-key="id"
                        @change="getDetails"
                        ref="searchSelect"
                        @clear="remoteInvoice"
                        clearable
                    >
                        <el-option
                            v-for="item in invoiceList"
                            :key="item.id"
                            :label="item.invoiceName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="税号" required>
                    <el-input
                        style="width: 290px"
                        placeholder="输入税号"
                        v-model="form.dutyNo"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="收件人"
                    :required="form.invoiceCategory == 2"
                >
                    <el-input
                        style="width: 290px"
                        placeholder="输入收件人"
                        v-model="form.receiveName"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="收件电话"
                    :required="form.invoiceCategory == 2"
                >
                    <el-input
                        style="width: 290px"
                        placeholder="输入收件电话"
                        v-model="form.receivePhone"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="收件邮箱"
                    :required="form.invoiceCategory == 1"
                >
                    <el-input
                        style="width: 290px"
                        placeholder="输入收件邮箱"
                        v-model="form.receiveEmail"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="收件地址"
                    :required="form.invoiceCategory == 2"
                >
                    <el-input
                        style="width: 290px"
                        placeholder="输入收件地址"
                        v-model="form.receiveAddress"
                    ></el-input>
                </el-form-item>
                <div v-if="form.invoiceType == 2">
                    <el-form-item label="开户银行" required>
                        <el-input
                            style="width: 290px"
                            placeholder="输入开户银行"
                            v-model="form.depositBank"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="银行账户" required>
                        <el-input
                            style="width: 290px"
                            placeholder="输入银行账户"
                            v-model="form.bankAccount"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="公司电话" required>
                        <el-input
                            style="width: 290px"
                            placeholder="输入公司电话"
                            v-model="form.companyPhone"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="注册地址" required style="width: 100%">
                        <el-input
                            style="width: 290px"
                            placeholder="输入注册地址"
                            v-model="form.registerAddress"
                        ></el-input>
                    </el-form-item>
                </div>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        style="width: 290px"
                        placeholder="输入备注"
                        v-model="form.remark"
                        :rows="3"
                    ></el-input>
                </el-form-item>
            </el-form>
        </div>

        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="
                    background: none;
                    border-color: #d5d6df;
                    color: #666666;
                    margin-left: 270px;
                "
                @click="onClose"
                >取 消</el-button
            >

            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff"
                size="mini"
                @click="onSubmit"
                >提 交</el-button
            >
        </div>
    </div>
</template>
<script>
import { invoiceList } from '@/api/contr/invoice.js';
import { contractList, contracGet } from '@/api/contr/contr.js';
import { getCustomerAuthentication } from '@/api/user/ae/customer.js';
import { invoiceAdd } from '@/api/contr/invoice.js';
export default {
    data() {
        return {
            data: {},
            companyData: '',
            options: [],
            invoiceList: [],
            contractData: [],
            contracId: '',
            form: {
                invoiceAmount: '',
                invoiceName: '',
                invoiceType: '',
                receiveName: '',
                receivePhone: '',
                receiveAddress: '',
                receiveEmail: '',
                dutyNo: '',
                depositBank: '',
                bankAccount: '',
                companyPhone: '',
                registerAddress: '',
                invoiceCategory: '',
                remark: '',
                typeB: '',
                contractB: '',
            },
            canInvoice: 0,
            invoiceNameVal: {},
        };
    },
    methods: {
        // 客户列表
        getCustomerAdmin(name) {
            this.form = {
                invoiceAmount: '',
                invoiceName: '',
                invoiceType: '',
                receiveName: '',
                receivePhone: '',
                receiveAddress: '',
                receiveEmail: '',
                dutyNo: '',
                depositBank: '',
                bankAccount: '',
                companyPhone: '',
                registerAddress: '',
                invoiceCategory: '',
                remark: '',
                contractB: '',
                typeB: '',
            };
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 1,
                pageSize: 20,
            };
            if (name) {
                data.param.name = name;
            }
            getCustomerAuthentication(data).then((res) => {
                this.options = res.data.list;
            });
            this.remoteInvoice();
        },
        // 合同列表
        getContractList() {
            let data = {
                param: {
                    // adminId: sessionStorage.getItem('adminId'),
                },
                pageNum: 0,
                pageSize: 0,
            };
            data.param.customerId = this.companyData.id;

            data.param.statusList = [12, 8, 7, 11, 10];
            data.param.billingStatusList = [1, 2];
            contractList(data).then((res) => {
                if (res.code == 200) {
                    this.contractData = res.data.list;
                }
            });
        },
        remoteMethod(query) {
            if (query) {
                this.getCustomerAdmin(query);
            }
        },

        remoteInvoice(query) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageSize: 10,
                pageNum: 1,
            };
            if (query) {
                data.param.name = query;
            }
            invoiceList(data).then((res) => {
                this.invoiceList = res.data.list;
            });
        },
        getContracData(contracId) {
            if (contracId) {
                let data = {
                    param: {
                        id: contracId.id,
                    },
                };
                contracGet(data).then((res) => {
                    this.data = res.data;
                    this.form.typeB = res.data.typeB;
                    this.getApplyData(res.data);
                    if (res.data.typeB == 9) {
                        this.form.contractB = res.data.contractB;
                    }
                });
            } else {
                this.data = {};
                this.form.typeB = '';
                this.form.contractB = '';
            }
        },
        getApplyData(data) {
            if (
                data.contractInvoiceBOList &&
                data.contractInvoiceBOList.length > 0
            ) {
                let num = 0;

                data.contractInvoiceBOList.forEach((item) => {
                    if (item.status == 1 || item.status == 2) {
                        num += item.invoiceAmount;
                    }
                });
                this.canInvoice = data.contractAmount - num;
            } else {
                this.canInvoice = data.contractAmount;
            }
        },
        getInvoiceList(query) {
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                },
                pageSize: 10,
                pageNum: 1,
            };
            if (query) {
                data.param.name = query;
            }
            invoiceList(data).then((res) => {
                this.invoiceList = res.data.list;
            });
        },
        getDetails() {
            if (this.invoiceNameVal.invoiceName) {
                this.form.invoiceName = this.invoiceNameVal.invoiceName;
                this.form.invoiceType = this.invoiceNameVal.invoiceType;
                this.form.invoiceCategory = this.invoiceNameVal.invoiceCategory;
                this.form.receiveName = this.invoiceNameVal.receiveName;
                this.form.receivePhone = this.invoiceNameVal.receivePhone;
                this.form.receiveAddress = this.invoiceNameVal.receiveAddress;
                this.form.receiveEmail = this.invoiceNameVal.receiveEmail;
                this.form.dutyNo = this.invoiceNameVal.dutyNo;
                this.form.depositBank = this.invoiceNameVal.depositBank;
                this.form.bankAccount = this.invoiceNameVal.bankAccount;
                this.form.companyPhone = this.invoiceNameVal.companyPhone;
                this.form.registerAddress = this.invoiceNameVal.registerAddress;
                this.form.remark = this.invoiceNameVal.remark;
            } else {
                this.form.invoiceName = this.invoiceNameVal;
            }
        },
        onSubmit() {
            let invoice = this.form;
            let canInvoice = this.canInvoice;
            if (!this.companyData) {
                return this.$message.error('请选择或输入客户');
            }
            if (!invoice.typeB) {
                return this.$message.error('请选择签署公司');
            }
            if (invoice.typeB == 9 && !invoice.contractB) {
                return this.$message.error('请输入自定义签署公司');
            }
            if (!invoice.invoiceAmount) {
                return this.$message.error('请输入开票金额');
            } else if (canInvoice < invoice.invoiceAmount && this.contracId) {
                return this.$message.error(
                    '开票金额大于合同可开票金额，无法申请'
                );
            } else if (!invoice.invoiceName) {
                return this.$message.error('请输入开票名称');
            } else if (!invoice.invoiceType) {
                return this.$message.error('请选择发票类型');
            } else if (!invoice.invoiceCategory) {
                return this.$message.error('请选择开票类型');
            } else if (!invoice.dutyNo) {
                return this.$message.error('请输入税号');
            } else if (invoice.invoiceType == 2 && !invoice.depositBank) {
                return this.$message.error('请输入开户银行');
            } else if (invoice.invoiceType == 2 && !invoice.bankAccount) {
                return this.$message.error('请输入银行账号');
            } else if (invoice.invoiceType == 2 && !invoice.companyPhone) {
                return this.$message.error('请输入公司电话');
            } else if (invoice.invoiceType == 2 && !invoice.registerAddress) {
                return this.$message.error('请输入注册地址');
            } else if (invoice.invoiceCategory == 1 && !invoice.receiveEmail) {
                return this.$message.error('请输入收件邮箱');
            } else if (invoice.invoiceCategory == 2 && !invoice.receiveName) {
                return this.$message.error('请输入收件人');
            } else if (invoice.invoiceCategory == 2 && !invoice.receivePhone) {
                return this.$message.error('请输入收件人电话');
            } else if (
                invoice.invoiceCategory == 2 &&
                !invoice.receiveAddress
            ) {
                return this.$message.error('请输入收件人地址');
            } else {
                let data = {
                    param: invoice,
                };
                data.param.customerId = this.companyData.id;
                data.param.contractId = this.contracId.id;
                invoiceAdd(data).then((res) => {
                    if (res.code == 200) {
                        this.$message.success('添加成功');
                        this.onClose();
                    }
                });
            }
        },
        onClose() {
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
.invoice_box {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding-bottom: 55px;
}
.contractData {
    width: 370px;
    height: 98px;
    background: #f8f9fa;
    border-radius: 4px;
    margin-bottom: 20px;
    p {
        padding-top: 8px;
        margin-left: 5px;
        span:nth-child(1) {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
        }
        span:nth-child(2) {
            font-size: 14px;
            font-weight: 400;
            color: #333;
            line-height: 20px;
            margin-left: 30px;
        }
    }
}
.bottom-but {
    width: 480px;
    line-height: 48px;
    text-align: right;
    background: #fff;
    border-top: 1px solid #dee0e3;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    button {
        width: 90px;
        z-index: 1000;
    }
}
</style>
