<template>
    <div class="declare">
        <div class="search">
            <span style="margin-right: 12px">快速查询</span>
            <el-input
                v-model="input"
                placeholder="搜索金额"
                size="small"
                style="width: 170px"
            ></el-input>
            <el-button
                @click="getData(customerId)"
                type="primary"
                size="small"
                style="background: #2370eb; border-color: #2370eb"
                icon="el-icon-search"
            ></el-button>
        </div>
        <div class="table">
            <el-table
                :data="gridData"
                height="100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '14px',
                    'font-weight': '500',
                }"
            >
                <el-table-column width="55">
                    <template slot-scope="scope">
                        <el-radio v-model="tableRadio" :label="scope.row"><i></i></el-radio>
                    </template>
                </el-table-column>
                <el-table-column label="提交日期">
                    <template slot-scope="scope">
                        <span>{{ $tableDataHandle.createTime(scope.row) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="客户名称">
                    <template slot-scope="scope">
                        <span v-if="scope.row.customer">
                            {{ scope.row.customer.companyName || '- -' }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column label="合同金额">
                    <template slot-scope="scope">
                        <span v-if="scope.row.contract">{{
                            (scope.row.contract.contractAmount || '0') + '元'
                        }}</span>
                        <span v-else> - - </span>
                    </template>
                </el-table-column>
                <el-table-column property="actualTotalAmount" label="申报金额"></el-table-column>
                <el-table-column label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ $cost.declareDetailType(scope.row.declareDetailList[0].type) }}
                    </template></el-table-column
                >
            </el-table>
        </div>
        <div class="bottom-but">
            <el-button
                round
                type="primary"
                size="mini"
                style="background: none; border-color: #d5d6df; color: #666666"
                @click="onClose"
                >取消</el-button
            >
            <el-button
                round
                type="primary"
                style="background: #2370eb; border-color: #2370eb; color: #fff; margin-right: 32px"
                size="mini"
                @click="onSubmit"
                >提交</el-button
            >
        </div>
    </div>
</template>
<script>
import { declareList } from '@/api/cost/declare.js';
export default {
    data() {
        return {
            input: '',
            gridData: [],
            tableRadio: '',
            customerId: '',
        };
    },
    methods: {
        getData(id) {
            this.customerId = id;
            let data = {
                param: {
                    adminId: sessionStorage.getItem('adminId'),
                    customerId: id,
                    status: 4,
                    payWay: 1,
                },
            };
            if (this.input != '') {
                data.param.minAmount = Number(this.input);
                data.param.maxAmount = Number(this.input);
            }
            declareList(data).then((res) => {
                this.tableRadio = '';
                this.gridData = res.data.list;
            });
        },
        onSubmit() {
            this.$emit('choiceDeclare', this.tableRadio);
            this.tableRadio = '';
            this.gridData = [];
        },
        onClose() {
            this.tableRadio = '';
            this.gridData = [];
            this.$emit('close');
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare {
    height: 100%;
    padding: 0 35px;
    .search {
        margin-top: 24px;
        display: flex;
        align-items: center;
    }
    .table {
        width: 100%;
        height: 343px;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        margin-top: 24px;
    }
    .bottom-but {
        width: 100%;
        line-height: 48px;
        background: #fff;
        border-top: 1px solid #dee0e3;
        margin-top: 16px;
        text-align: right;
        button {
            width: 90px;
        }
    }
}
</style>
