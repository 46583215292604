<template>
    <div class="apply">
        <div class="search-box">
            <div class="search-one">
                <p class="left">
                    <span>类型</span>
                    <el-radio-group v-model="isType" size="small">
                        <el-radio-button
                            label="0"
                            @click.native.prevent="isType = 0"
                            >全部</el-radio-button
                        >
                        <el-radio-button
                            label="1"
                            @click.native.prevent="isType = 1"
                            >销售</el-radio-button
                        >
                        <el-radio-button
                            label="2"
                            @click.native.prevent="isType = 2"
                            >费用</el-radio-button
                        >
                    </el-radio-group>

                    <span>查询</span>
                    <el-input
                        size="small"
                        style="width: 180px"
                        placeholder="审批事项"
                        v-model="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-search"
                        style="margin-right: 0"
                    ></el-button>
                </p>
            </div>
        </div>
        <div class="apply_box">
            <div class="sale_box" v-if="isType != 2 && btnP.invoice">
                <p class="sale_title">销售</p>
                <div>
                    <p @click="onPopup(1)">
                        <img src="@/assets/编组 12.png" alt="" /> 发票
                    </p>
                </div>
            </div>
            <div class="cost_box" v-if="isType != 1">
                <p class="cost_title">费用</p>
                <div>
                    <p @click="onPopup(2)" v-if="btnP.recharge">
                        <img src="@/assets/编组 4.png" alt="" /> 充值
                    </p>

                    <p @click="onPopup(3)" v-if="btnP.declare">
                        <img src="@/assets/编组 13.png" alt="" /> 销售费用申报
                    </p>

                    <!-- <p @click="onPopup(4)" v-if="btnP.reimb">
                        <img src="@/assets/编组 16.png" alt="" /> 费用报销
                    </p> -->
                </div>
            </div>
        </div>
        <el-drawer
            :visible.sync="drawer"
            :direction="direction"
            :before-close="handleDrawer"
            size="500px"
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 500;
                        color: rgba(0, 0, 0, 0.85);
                        line-height: 24px;
                    "
                >
                    {{ drawerTitle }}
                </div>
            </template>
            <div v-if="isDrawer == 1">
                <Invoice ref="invoice" @close="handleDrawer" />
            </div>

            <div v-if="isDrawer == 2">
                <Recharge ref="recharge" @close="handleDrawer" />
            </div>

            <div v-if="isDrawer == 4" style="height: 100%">
                <Reimb ref="reimb" @close="handleDrawer" />
            </div>
        </el-drawer>
    </div>
</template>
<script>
import Invoice from './invoice.vue';
import Recharge from './recharge.vue';
import Declare from './declare.vue';
import Reimb from './reimb/reimb.vue';
export default {
    components: {
        Invoice,
        Recharge,
        Declare,
        Reimb,
    },
    data() {
        return {
            searchVal: {},
            isType: 0,
            drawerTitle: '',
            drawer: false,
            direction: 'rtl',
            isDrawer: 0,
            btnP: {},
        };
    },
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
            }
            this.btnP = btn;
            // console.log(btn);
        },
        onPopup(i) {
            this.isDrawer = i;
            this.drawer = true;
            if (i == 1) {
                this.drawerTitle = '发票';
                setTimeout(() => {
                    this.$refs.invoice.getCustomerAdmin();
                });
            }
            if (i == 2) {
                this.drawerTitle = '充值';
                setTimeout(() => {
                    this.$refs.recharge.launchList();
                });
            }
            if (i == 3) {
                // this.drawerTitle = '费用申报';
                sessionStorage.setItem('customerlist', 1);
                // setTimeout(() => {
                //     this.$refs.declare.getCustomerList();
                // }, 0);
                this.$router.push({
                    path: '/addcostdeclare',
                });
            }
            if (i == 4) {
                this.drawerTitle = '费用报销';
                sessionStorage.setItem('customerlist', 1);
                setTimeout(() => {
                    this.$refs.reimb.getCustomerList();
                }, 0);
            }
        },
        handleDrawer() {
            this.drawer = false;
        },
    },
};
</script>
<style lang="scss" scoped>
.apply {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.search-box {
    width: 100%;
    background: #fff;
    border-radius: 4px;
    margin-bottom: 16px;
    span {
        font-size: 12px;
        font-weight: 500;
        color: #333333;
        margin: 0 16px;
    }
    .search-one {
        padding: 10px 0 12px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .left {
            button {
                background: #2370eb;
                border-radius: 2px;

                margin-right: 16px;
            }
        }
    }
    .search-two {
        padding-bottom: 10px;
    }
}
.apply_box {
    flex: 1;
    background: #ffffff;
    border-radius: 4px;
    padding-left: 12px;
}
.sale_box {
    .sale_title {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        padding: 11px 0;
    }
    div {
        p {
            width: 160px;
            height: 48px;
            border-radius: 4px;
            border: 1px solid #e5e5e5;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            cursor: pointer;
            img {
                margin-right: 24px;
            }
        }
    }
}
.cost_box {
    .cost_title {
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
        padding: 11px 0;
    }
    div {
        display: flex;
        p {
            cursor: pointer;
            width: 160px;
            height: 48px;
            border-radius: 4px;
            border: 1px solid #e5e5e5;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 40px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            img {
                margin-right: 24px;
            }
        }
    }
}
</style>
